<template>
    <div class="container">
      <img class="background" src="@/assets/section-one/Fondo portada.png" alt="Fondo" />
      <img class="logo" src="@/assets/section-one/Logo Colgate.png" alt="Logo Colgate" />
      <div class="content">
        <div class="text-container">
          <img class="text" src="@/assets/section-one/Texto portada.png" alt="Texto Portada" />
          <img class="smile" src="@/assets/section-one/Smile.png" alt="Smile" />
        </div>
      </div>
      <img class="bottom-image" src="../assets/section-one/Legal 1.png" alt="Bottom Image" />
    </div>
  </template>
  
  <script>
  export default {
    name: "SectionOne",
  };
  </script>
  
  <style scoped>
  .container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .logo {
    position: absolute;
    top: 0; /* Toca el borde superior */
    left: 50px; /* Ajuste del margen desde el borde izquierdo */
    width: 15%; /* Tamaño más grande */
    max-width: 200px; /* Máximo más grande */
    z-index: 3;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }
  
  .text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    width: 80%;
    max-width: 600px;
  }
  
  .smile {
    margin-top: 10px;
    width: 40%; /* Tamaño más grande */
    max-width: 400px;
  }

  .bottom-image {
  position: absolute;
  bottom: 14px;
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  z-index: 3;
}

  @media screen and (max-width: 768px) {
    .logo {
      width: 30%; /* Ajuste para pantallas pequeñas */
    }
    .text {
      width: 90%;
    }
    .smile {
      width: 60%; /* Ajuste para pantallas pequeñas */
    }

    .bottom-text {
    font-size: 16px;
    bottom: 10px;
  }
  }
  
  @media screen and (min-width: 1180px) and (min-height: 820px) {
    .content {
      max-width: 60%;
    }
    .logo {
      width: 15%; /* Más grande en este formato */
    }
    .text {
      width: 90%; /* Texto más grande en este formato */
    }
    .smile {
      width: 45%; /* Smile más grande en este formato */
    }
  }

  /* animaciones */
  @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUpFadeIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Configuración inicial (invisible) */
.background,
.logo,
.text,
.smile,
.bottom-image {
  opacity: 0; /* Todos los elementos comienzan invisibles */
}

/* Animaciones */
.background {
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
}

.logo {
  animation: slideDown 1s ease-in-out 0.5s;
  animation-fill-mode: forwards;
}

.text {
  animation: slideInLeft 1s ease-in-out 1s;
  animation-fill-mode: forwards;
}

.smile {
  animation: slideUpFadeIn 1s ease-in-out 1.5s;
  animation-fill-mode: forwards;
}

.bottom-image {
  animation: fadeIn 1s ease-in-out 1.5s;
  animation-fill-mode: forwards;
}
  </style>
  