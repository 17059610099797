<template>
  <div>
    <!-- Mostrar el componente de login si no está autenticado -->
    <Login v-if="!isAuthenticated" @loginSuccess="handleLoginSuccess" />

    <!-- Mostrar la landing page si está autenticado -->
    <div v-else>
      <!-- Botón para cerrar sesión -->
      <button @click="logout" class="logout-button">Cerrar Sesión</button>

      <div v-if="appVisible" id="app">
        <!-- Imagen centrada -->
        <img src="../src/assets/ciclo1.png" alt="Header Image" class="header-image colgate" />

        <!-- Botones para seleccionar secciones -->
        <div class="button-container botones">
          <button @click="showSection('perioCirugia', 'perio/cirugia/implantes/prosto')" class="button-perio">
            PERIO CIRUGÍA IMPLANTES PROSTO
          </button>
          <button @click="showSection('orthoOdontoped', 'ortho/odontoped')" class="button-ortho">
            ORTHO ODONTOPED
          </button>
          <button @click="showSection('generalEndoEstetica', 'general/endodoncia/estetica')" class="button-general">
            GENERAL ENDODONCIA ESTÉTICA
          </button>
        </div>
      </div>

      <div v-else>
        <!-- Botón para regresar al inicio -->
        <img @click="showHome" class="home-button" v-show="currentView !== 'home'" src="../src/assets/home_ciclo1.svg" alt="Home Button" />

        <!-- Secciones dinámicas -->
        <div v-for="section in currentSections" :key="section">
          <component :is="section" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./components/Login.vue";
import SectionOne from "./components/SectionOne.vue";
import SectionTwo from "./components/SectionTwo.vue";
import SectionThree from "./components/SectionThree.vue";
import SectionFour from "./components/SectionFour.vue";
import SectionFive from "./components/SectionFive.vue";
import SectionSix from "./components/SectionSix.vue";
import SectionSeven from "./components/SectionSeven.vue";
import SectionEight from "./components/SectionEight.vue";
import SectionNine from "./components/SectionNine.vue";
import SectionTen from "./components/SectionTen.vue";
import SectionEleven from "./components/SectionEleven.vue";

export default {
  name: "App",
  components: {
    Login,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    SectionEight,
    SectionNine,
    SectionTen,
    SectionEleven,
  },
  data() {
    return {
      isAuthenticated: false, // Controla si el usuario está autenticado
      currentView: "home",
      currentSections: [],
      appVisible: true,
      sessionTimeout: null, // Referencia al temporizador de la sesión
    };
  },
  methods: {
    handleLoginSuccess() {
      this.isAuthenticated = true; 
      console.log("Inicio de sesión exitoso.");
    },
    logout() {
      // Limpiar el estado de autenticación y localStorage
      console.log("Cerrando sesión...");
      this.isAuthenticated = false;
      localStorage.removeItem("userId");

      // Redirigir al login
      this.currentView = "home";
      this.appVisible = true;

      // Limpiar el temporizador de la sesión
      if (this.sessionTimeout) clearTimeout(this.sessionTimeout);
      console.log("Sesión cerrada correctamente.");
    },
    showSection(sectionGroup, slug) {
      this.appVisible = false; // Ocultar la vista principal
      switch (sectionGroup) {
        case "perioCirugia":
          this.currentSections = [
            "SectionOne",
            "SectionTwo",
            "SectionThree",
            "SectionFour",
            "SectionFive",
            "SectionSix",
            "SectionEight",
            "SectionSeven",
            "SectionNine",
            "SectionEleven",
          ];
          break;
        case "orthoOdontoped":
          this.currentSections = [
            "SectionOne",
            "SectionTwo",
            "SectionThree",
            "SectionTen",
            "SectionFour",
            "SectionFive",
            "SectionSix",
            "SectionEleven",
          ];
          break;
        case "generalEndoEstetica":
          this.currentSections = [
            "SectionOne",
            "SectionTwo",
            "SectionThree",
            "SectionEight",
            "SectionSeven",
            "SectionNine",
            "SectionFour",
            "SectionFive",
            "SectionSix",
            "SectionEleven",
          ];
          break;
      }
      this.currentView = "sections";
      window.history.pushState(null, "", `/${slug}`);
    },
    showHome() {
      this.appVisible = true;
      this.currentView = "home";
      this.currentSections = [];
      window.history.pushState(null, "", "/");
    },
    handlePopState() {
      if (window.location.pathname === "/") {
        this.showHome();
      }
    },
  },
  mounted() {
    const userId = localStorage.getItem("userId");
    if (userId) {
      this.isAuthenticated = true;
      console.log("Usuario autenticado al cargar.");
    }
    window.addEventListener("popstate", this.handlePopState);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopState);
  },
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
}

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: darkred;
}

.header-image {
  display: block;
  margin: 10px auto;
  width: 70%;
  max-width: 500px;
}

.colgate {
  margin-top: 100px;
  width: 380px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 486px); */
}

button {
  margin: 10px;
  padding: 15px 30px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 40px;
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
}

.button-perio {
  background-color: #287db2;
  color: white;
}

.button-ortho {
  background-color: #56ad96;
  color: white;
}

.button-general {
  background-color: #bea43f;
  color: white;
}

.home-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 100;
}
</style>