<template>
  <section class="responsive-section" ref="section">
    <img src="../assets/section-one/Logo Colgate.png" alt="Logo" class="logo animated-logo" />
    <div class="content">
      <img
        src="../assets/section-eleven/Título Colgate pass.png"
        alt="Título"
        class="title-image animated-title"
      />
      <img
        src="../assets/section-eleven/Paso a Paso.png"
        alt="Paso a Paso"
        class="center-image animated-step"
      />
    </div>
    <img src="../assets/section-eleven/Legal 2.png" alt="Imagen Inferior" class="bottom-image">
  </section>
</template>

<script>
export default {
  name: "SectionEleven",
  mounted() {
    const images = this.$el.querySelectorAll('.animated-logo, .animated-title, .animated-step');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
            observer.unobserve(entry.target); // Desconectar después de activar
          }
        });
      },
      { threshold: 0.5 } // Activar cuando el 50% del elemento sea visible
    );

    images.forEach((img) => observer.observe(img));
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scalePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Animación de "respiro" más sutil */
  }
  100% {
    transform: scale(1);
  }
}

/* Animaciones para cada elemento */
.animated-logo,
.animated-title,
.animated-step {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease-out, transform 2s ease-out; /* Entrada más lenta */
}

.animated-logo.is-visible,
.animated-title.is-visible,
.animated-step.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.animated-step {
  width: 95%; /* Aumenta el tamaño de la imagen */
  max-width: 900px; /* Ajusta el tamaño máximo */
  animation: scalePulse 8s ease-in-out 1s infinite alternate; /* Animación más lenta y suave */
}

/* Código original intacto */
.responsive-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #25140F;
  background-image: url("../assets/section-eleven/Fondo_1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
}

.logo {
  width: 15%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: -3;
  left: 50px;
  z-index: 10;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 50px 0;
  box-sizing: border-box;
}

.title-image {
  width: 60%;
  max-width: 400px;
  height: auto;
  margin-top: 6px;
}

.center-image {
  width: 95%;
  max-width: 864px;
  height: auto;
  margin-top: 20px;
}

.bottom-image {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
}

/* Ajustes responsivos */
@media (max-width: 1180px) {
  .logo {
    width: 15%;
    top: 0;
    left: 50px;
  }
  .title-image {
    width: 70%;
    max-width: 365px;
  }
  .center-image {
    width: 100%;
    position: absolute;
    bottom: -21px;
  }
  .bottom-image {
    width: 90%;
    max-width: 1050px;
  }
}

@media (max-width: 820px) {
  .responsive-section {
    height: auto;
  }
  .logo {
    width: 100px;
    top: -3;
    left: 10px;
  }
  .title-image {
    width: 80%;
    max-width: 250px;
  }
  .center-image {
    width: 100%;
    max-width: none;
  }
  .bottom-image {
    width: 100%;
    max-width: none;
  }
}
</style>
