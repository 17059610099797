<template>
  <div class="login-container">
    <h1 class="login-title">Iniciar Sesión</h1>
    <form @submit.prevent="handleLogin" class="login-form">
      <div class="form-group">
        <label for="cedula" class="form-label">Cédula</label>
        <input
          type="text"
          id="cedula"
          v-model="cedula"
          placeholder="Ingresa tu cédula"
          required
          class="login-input"
        />
      </div>
      <button type="submit" class="login-button">Iniciar Sesión</button>
    </form>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      cedula: "", // Cédula del usuario
      errorMessage: "", // Mensaje de error en caso de fallo
    };
  },
  methods: {
    async handleLogin() {
      try {
        // Realiza una solicitud POST al backend
        const response = await axios.post("https://ciclo1-2025.kagencia.com/auth/login", { /* http://localhost:3000 - https://ciclo1-2025.kagencia.com*/
          cedula: this.cedula,
        }, { withCredentials: true });

        // Si la solicitud fue exitosa
        if (response.status === 200) {
          // Almacena el userId en localStorage
          localStorage.setItem("userId", response.data.userId);

          // Emite un evento para informar que el login fue exitoso
          this.$emit("loginSuccess");
        }
      } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Error al intentar iniciar sesión.";
        }
      }
    },
  },
};
</script>

<style scoped>
/* Contenedor general del login */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Centrado vertical */
  background-color: #f5f5f5; /* Fondo sólido */
  color: #333;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Título del login */
.login-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Formulario */
.login-form {
  width: 100%;
  max-width: 400px;
  background-color: #ffffff; /* Fondo blanco */
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  box-sizing: border-box;
}

/* Etiquetas */
.form-label {
  font-family: sans-serif;
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

/* Input */
.login-input {
  width: 100%; /* Asegura que el input no se salga del contenedor */
  padding: 10px 15px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  transition: border 0.3s ease;
}

.login-input:focus {
  border-color: #007bff;
}

/* Botón */
.login-button {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Efecto al pasar el mouse */
}

/* Mensaje de error */
.error-message {
  color: red;
  margin-top: 15px;
  font-size: 0.9rem;
}
</style>
